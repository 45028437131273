import React from 'react';
import ReactDOM from 'react-dom';
import { Link, graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const pixel_result = (pvalue) =>{
  return (
    <b>{pvalue}</b>
  )
}

const handleInputChange = event => {
  event.preventDefault();
  ReactDOM.render(0, document.getElementById('result_width'))
  ReactDOM.render(0, document.getElementById('result_height'))
}

const handleSubmit = event => {
  event.preventDefault();
  const target = event.target
  const mm_per_dpi = 25.4
  const pixel_width = (target.width.value/mm_per_dpi) * target.dpi.value
  const pixel_height = (target.height.value/mm_per_dpi) * target.dpi.value
  ReactDOM.render(pixel_result(Math.round(pixel_width)), document.getElementById('result_width'))
  ReactDOM.render(pixel_result(Math.round(pixel_height)), document.getElementById('result_height'))  
}

const Services = (props) => {
  return (
    <Layout bodyClass="page-services">
      <SEO title="Bildgrößenrechner" 
        description="Bildgrößenrechner zur Umrechnung von Millimetern zu Pixel mit Erklärungen zur verschiedenen Qualitäten und DPI."
      />
      <div className="strip strip-white strip-diagonal no-strip-mobile">
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Bildgrößenrechner</h1>
              <h3>Schnell und einfach von Millimeter zu Pixel umrechnen</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-6">
        <div className="row justify-content-between">
        <div className="content col-12 col-md-4">
        
        <h2>Druckgröße</h2>
          <form
            onSubmit={handleSubmit}
          >
            <div className="form-group">
            <label>
              Breite: 
            </label>
            <input 
                type="number"
                name="width"
                id="width_input"
                className="form-control"
                onChange={handleInputChange}
              />
              <small className="form-text text-muted">Breite in Millimeter</small>
            </div>
            <div className="form-group">
            <label>
              Höhe: 
            </label>
            <input 
                type="number"
                name="height"
                id="height_input"
                className="form-control"
                onChange={handleInputChange}
              />
              <small className="form-text text-muted">Höhe in Millimeter</small>
            </div>
            <div className="form-group">
            <label>
              Qualität
            </label>
            <select
                onChange={handleInputChange}
                name="dpi"
                className="form-control"
                id="dpi"
              >
                <option value="300">Hohe Qualität (300dpi)*</option>
                <option value="150">Mittlere Qualität (150dpi)*</option>
                <option value="92">Einfache Qualität (92dpi)*</option>
              </select>
              <small className="form-text text-muted">* Erklärung zur Qualität siehe unten</small>
            </div>
            <button type="submit" className="btn btn-primary btn-block">Umrechnen</button>
            
          </form>
          </div>
          <div id="result" className="col-12 col-md-6 content mt-2 mt-md-0">
            <h2>Ergebnis:</h2>
            <p>Das Ergebnis gibt die Pixelwerte für die angegebene Größe und die Qualität wieder.</p>
            <div className="col-12 text-primary">Breite: <span id="result_width"></span> Pixel(px)</div>
            <div className="col-12 text-primary">Höhe: <span id="result_height"></span> Pixel(px)</div>
          </div>
          <div id="erklaerung" className="col-12 content mt-4">
            <h2>Erklärung:</h2>
            <h3>Qualität in dpi</h3>
            <ul>
              <li>Hohe Qualität (300dpi)
                <ul>
                  <li>Industriestandard für Druckprodukte</li>
                  <li>1 Pixel entspricht später im Druck ca 0.08mm Breite und Höhe</li>
                  <li>Pixel im Druck nicht sichtbar selbst bei näherer Betrachtung</li>
                </ul>
              </li>
              <li>Mittlere Qualität (150dpi)
                <ul>
                  <li>Ausreichende Qualität für viele Anwendungen</li>
                  <li>1 Pixel entspricht später im Druck ca 0.17mm Breite und Höhe</li>
                  <li>Pixel kaum sichtbar bei näherer Betrachtung</li>
                </ul>
              </li>
              <li>Einfache Qualität (92dpi)
                <ul>
                  <li>Ausreichende Qualität bei großformatigen Drucken, die aus größerer Entfernung angeschaut werden</li>
                  <li>1 Pixel entspricht später im Druck ca 0.28mm Breite und Höhe</li>
                  <li>Pixel gut sichtbar bei näherer Betrachtung</li>
                </ul>  
              </li>
            </ul>
          </div>
        </div>
      </div>
      </div>
    </Layout>
  );
};

export default Services;
